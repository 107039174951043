export default [
  {
    key: 'floema',
    title: 'Floema Jewelry',
    shortTitle: 'Floema',
    description: 'Category: Fashion',
    credit:
      'WEB DESIGN & DEVELOPMENT\n( MASTERCLASS )\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://floema.jewelry/',
    textWidth: 700
  },
  {
    key: 'fcxv',
    title: 'Fabienne Chapot',
    shortTitle: 'FCXV',
    description: 'Category: Fashion',
    credit: 'WEB DESIGN & DEVELOPMENT\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://fcxv.fabiennechapot.com/',
    textWidth: 700
  },
  {
    key: 'powerhouse',
    title: 'POWERHOUSE',
    shortTitle: 'POWERHOUSE',
    description: 'Category: Architecture',
    credit: 'WEB DESIGN & DEVELOPMENT\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://www.powerhouse-company.com/',
    textWidth: 700
  },
  {
    key: 'luckyfolks',
    title: 'LUCKY FOLKS',
    shortTitle: 'LUCKYFOLKS',
    description: 'Category: Restaurant & Cafe',
    credit: 'WEB DESIGN & DEVELOPMENT\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://www.luckyfolks.fr/',
    textWidth: 700
  },
  {
    key: 'joseph-berry',
    title: 'JOSEPH BERRY',
    shortTitle: 'JBMC',
    description: 'Category: Portfolio',
    credit:
      'WEB DESIGN & DEVELOPMENT\n( MASTERCLASS )\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://joseph-berry-webflow-master-class.webflow.io/',
    textWidth: 390
  },
  {
    key: 'canpolat-mimarlik',
    title: 'Canpolat MİMARLIK',
    shortTitle: 'Canpolat',
    description: 'Category: Architecture',
    credit:
      'WEB DESIGN & DEVELOPMENT\nLOGO DESIGN\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://canpolatmimarlik.com/',
    textWidth: 1260
  },
  {
    key: 'a90',
    title: 'A90 YAPI',
    shortTitle: 'A90 YAPI',
    description: 'Category: Construction',
    credit: 'WEB DESING & DEVELOPMENT\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://a90yapi.com',
    textWidth: 940
  },
  {
    key: 'nosi-insaat',
    title: 'NOSİ MİMARLIK',
    shortTitle: 'NOSİ',
    description: 'Category: Architecture',
    credit:
      'WEB DESIGN & DEVELOPMENT\nLOGO DESIGN\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://nosiinsaatmimarlik.com/',
    textWidth: 1260
  },
  {
    key: 'ident',
    title: 'iDENT KLİNİK',
    shortTitle: 'IDENT',
    description: 'Category: Dental Clinic',
    credit:
      'WEB DESIGN & DEVELOPMENT\nSOCIAL MEDIA MANAGEMENT\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'http://identklinik.com',
    textWidth: 390
  },
  {
    key: 'airland',
    title: 'AIRLAND HAVACILIK',
    shortTitle: 'AIRLAND',
    description: 'Category: AVIATION',
    credit:
      'WEB DESIGN & DEVELOPMENT\nSOCIAL MEDIA MANAGEMENT\nLOGO DESIGN\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://airlandhavacilik.com',
    textWidth: 1720
  },
  {
    key: 'my-old-website',
    title: 'Onur YÜKTAŞIR',
    shortTitle: 'ONRYKTSR',
    description: 'Category: Portfolio',
    credit: 'My Old Website\nDate: 2021\nDeveloper : Onur YÜKTAŞIR',
    href: 'https://30ac8369.onuryuktasir.pages.dev',
    textWidth: 390
  }
];
