<script>
export default {
  name: 'HomeHeading'
};
</script>

<template lang="pug">
  h1.p-home-heading
    |Onur Yüktaşır
    br
    |Front-end &amp; Creative Developer
</template>

<style lang="scss">
.p-home-heading {
  display: none;
}
</style>
