<script>
import sleep from 'js-util/sleep';
import MathEx from 'js-util/MathEx';
import normalizeWheel from 'normalize-wheel';

import store from '@/store';
import WhoIAmSection from '@/components/who-i-am/WhoIAmSection.vue';
import WhoIAmHeading from '@/components/who-i-am/WhoIAmHeading.vue';
import WhoIAmThanks from '@/components/who-i-am/WhoIAmThanks.vue';
import WhoIAmLinks from '@/components/who-i-am/WhoIAmLinks.vue';

export default {
  name: 'WhoIAm',
  metaInfo: {
    title: 'Who I am / '
  },
  components: {
    WhoIAmSection,
    WhoIAmHeading,
    WhoIAmThanks,
    WhoIAmLinks
  },
  data() {
    return {
      scrollY: 0,
      anchorY: 0,
      anchorYPrev: 0,
      clientHeight: 0,
      isRendering: false
    };
  },
  watch: {
    async '$store.state.resolution.y'() {
      await sleep(10);
      this.resize();
    }
  },
  computed: {
    styles() {
      return {
        paddingTop: `${this.$store.state.resolution.y / 2}px`,
        transform: `translate3d(0, ${-this.scrollY}px, 0)`
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    store.commit('transit', {
      globalId: 50
    });
    next();
  },
  created() {
    window.addEventListener('wheel', this.wheel, { passive: false });
    window.addEventListener('touchstart', this.touchstart);
    window.addEventListener('touchmove', this.touchmove);
    this.scrollY = 0;
    this.anchorY = 0;
    this.anchorYPrev = 0;
    this.$store.commit('setScrollProgress', 0);
  },
  async mounted() {
    const { commit } = this.$store;

    commit('changeBackground', {
      isHome: false,
      hasDelay: false
    });
    commit('showHomeObjs', false);
    commit('showWorksObjs', {
      index: 0,
      direction: 1
    });
    commit('showWhoIAmObjs', true);
    await sleep(500);
    commit('showUI');
    this.isRendering = true;
    this.resize();
    this.update();
  },
  destroyed() {
    window.removeEventListener('wheel', this.wheel, { passive: false });
    window.removeEventListener('touchstart', this.touchstart);
    window.removeEventListener('touchmove', this.touchmove);
    this.isRendering = false;
  },
  methods: {
    update() {
      const { state, commit } = this.$store;

      this.scrollY =
        Math.floor((this.scrollY + (this.anchorY - this.scrollY) / 10) * 100) /
        100;
      commit(
        'setScrollProgress',
        this.scrollY / (this.clientHeight - state.resolution.y)
      );
      if (this.isRendering === true) {
        requestAnimationFrame(this.update);
      }
    },
    wheel(e) {
      e.preventDefault();

      const n = normalizeWheel(e);
      const { state, commit } = this.$store;

      if (state.isWheeling === true) return;

      if (this.scrollY < 1 && n.pixelY < 0) {
        // Go to the previous page.
        commit('startWheeling');
        this.$router.push(`/works/${state.works[state.works.length - 1].key}/`);
      } else {
        // Scroll the content of the current page.
        this.anchorY = MathEx.clamp(
          this.anchorY + n.pixelY,
          0,
          this.clientHeight - state.resolution.y
        );
      }
    },
    touchstart() {
      this.anchorYPrev = this.anchorY;
    },
    touchmove() {
      const { state, commit, dispatch } = this.$store;

      if (state.isTouchMoving === true) {
        if (this.scrollY < 1 && state.touchMove.y > 10) {
          // Go to the previous page.
          dispatch(
            'debounceRouterPush',
            `/works/${state.works[state.works.length - 1].key}/`
          );
          commit('touchEnd');
        } else {
          // Scroll the content of the current page.
          this.anchorY = MathEx.clamp(
            this.anchorYPrev - state.touchMove.y * 1.5,
            0,
            this.clientHeight - state.resolution.y
          );
        }
      }
    },
    resize() {
      const { state, commit } = this.$store;

      this.clientHeight = this.$refs['whoiam-wrap'].clientHeight;
      this.anchorY = MathEx.clamp(
        this.anchorY,
        0,
        this.clientHeight - state.resolution.y
      );
      commit(
        'setScrollProgress',
        this.scrollY / (this.clientHeight - state.resolution.y)
      );
    }
  }
};
</script>

<template lang="pug">
.p-view-wrap
  .p-whoiam-wrap(
    :style = 'styles'
    ref = 'whoiam-wrap'
    )
    .p-whoiam-wrap__in
      WhoIAmHeading
      WhoIAmSection(
        :num = '1'
        :scrollY = 'scrollY'
        :parallaxRatio = '0.1'
        )
        h2
          |Web Designer &amp; Developer.
          br
          |Math &amp; English Teacher. 
        p
          |I'm Onur YÜKTAŞIR. Besides being English / Math Teacher, I'm a Creative Developer. I also provide drawing and design program trainings for architects and engineers.
        p
          |My career as a web developer started in 2017.
          |I had the opportunity to help building projects for many companies.
          |My goal is to connect people with brands and bussiness thruogh design. A combination of strong concepts and refind execution to deliver premium results. Basically I like create cool shit.
        p
          |I am always available for interesting collobrations and fun side projects. If you would like to work together, feel free to get in touch.
      WhoIAmSection(
        :num = '2'
        )
        h2
          |EDUCATION
        p
          |Necmettin Erbakan University Mathematics Teacher (Bachelor)
        p
          |Istanbul University Business Administration (Bachelor)
          br
          br
        h2
          |CERTIFICATES I'VE RECIEVED
        p
          |introduction to business management certificate of achievement
        p
          |certificate of completion in Introduction to Teaching English as a Second Language
        p
          |Certificate of Teaching English as a Foreign Language (TEFL)
        p
          |Proficiency certificate of english language (Advanced-C2)
        p
          |Comprehensive Spanish completion certificate from beginner to advanced
        p
          |Certificate of Achievement HOW TO TEACH ONLINE: ENSURING CONTINUITY FOR STUDENTS
        p
          |Certificate of Wordpress Expertise
        p
          |Certificate of Completion HTML,CSS,JS &amp; JQUERY Online Course
        p
          |Certificate of Building an Immersive Creative Website from Scratch without Frameworks
        p
          |certificate of complete web design from figma to webflow to freelancing
        p
          |certificate of create award winning animation and interaction design in webflow
        p
          |certificate of creating a simple portfolio website with webgl and barba.js
        p
          |certificate of figma user interface design essentials ui &amp; ux design
        p
          |Certificate of Making a Difference with Correct Marketing Communication
        p
          |Certificate of General Occupational Health and Safety
        p
          |Certificate of foreign trade
        p
          |Certificate of personal stress management
        p
          |Certificate of Complaint Management
        p
          |Certificate of Dealing with Difficult People
        p
          |Technology and Efficiency Key Innovative Companies Training Certificate
        p
          |Innovation training certificate
        p
          |Certificate of Teaching with Technology (e-Teach)
        p
          |Certificate of 3D Modeling with Autodesk 123D
        p
          |Certificate of CAD / CAM Fundamentals
        p
          |Seminar Participation Certificate of "Branding Your Idea and Business , Entrepreneurship" 
        p
          |Seminar Participation Certificate of "Self-Confidence and Border Boots"
        p
          |Seminar Participation Certificate of "Psychological Resilience and Emotion Management"
        p
          |Seminar Participation Certificate of "Being Stronger and More Effective From Corona"
        p
          |PARTICIPATION CERTIFICATE of IEEE Marmara TECHCO(N!) '21 EVENT
        p
          |PARTICIPATION CERTIFICATE of How to Become a Youtuber
      WhoIAmSection(
        :num = '3'
        :scrollY = 'scrollY'
        :parallaxRatio = '0.1'
        )
        h2
          |WHAT I OFFER
        p
          |Web Design &amp; Development
        p
          |Corporate Identity and Logo Design
        p
          |Social Media Management
        p 
          |English &amp; Spanish Private Lessons for Non-Native Speakers
        p
          |Drawing and Design Program Trainings for Architects and Engineers
        
      WhoIAmLinks(
        :scrollY = 'scrollY'
        :parallaxRatio = '0.05'
        )
      WhoIAmThanks
</template>

<style lang="scss">
.p-whoiam-wrap {
  @include l-more-than-mobile {
    margin-right: 7.5%;
    margin-left: 7.5%;
    padding-bottom: 300px;
  }
  @include l-mobile {
    margin-right: 44px;
    margin-left: 44px;
    padding-bottom: 44px;
  }
  &__in {
    position: relative;
    margin-top: -25px;
  }
}
</style>
